import axios from 'axios'
import {
  SHOW_LOG,
  CHUNK_SIZE,
  API_URL_UPLOAD,
  STATUS_IN_PROGRESS,
  TIME_TO_WAIT_BEFORE_RETRY,
  MESSAGE_MAX_ATTEMPS_REACHED,
  MESSAGE_UPLOAD_CANCELED,
  MESSAGE_UPLOADING_CHUNK,
  MESSAGE_RETRYING,
  MESSAGE_FILE_SIZE,
  MESSAGE_ILLIGAL_FILE_TYPE
} from '../../config'

const state = {
  file: null,
  chunks: [],
  uploaded: 0,
  retryCount: 0,
  status: {
    type: 'info',
    message: 'Stand by'
  },
  totalChunks: 0,
  isError: false,
  source: null,
  cancel: null,
  retry: true,
  maxRetry: 15,
  uploading: false,
  currentTimer: null,
  showNotification: false
}

const mutations = {
  CLEAN_VARIABLES: (state, ref = null) => {
    state.uploading = false
    state.retryCount = 0
    state.retry = true
    state.file = null
    state.chunks = []
    state.totalChunks = 0
    if (ref) { ref.value = '' }
  },
  SET_FILE: (state, file = null) => {
    state.file = file
  },
  CREATE_CHUNKS: state => {
    state.chunks = []
    const chunks = Math.ceil(state.file.size / CHUNK_SIZE)
    state.totalChunks = chunks

    for (let i = 0; i < chunks; i++) {
      const piece = state.file.slice(i * CHUNK_SIZE, Math.min(i * CHUNK_SIZE + CHUNK_SIZE, state.file.size), state.file.type)
      state.chunks.push(piece)
    }
  },
  SET_UPLOADED: (state, value) => {
    state.uploaded = value
  },
  SET_RETRY_COUNT: (state, value) => {
    state.retryCount = value
  },
  SET_UPLOADING: (state, value) => {
    state.uploading = value
  },
  SET_CANCEL: (state, cancelFunction) => {
    state.cancel = cancelFunction
  },
  ADD_UPLOADED: (state, value) => {
    state.uploaded += value
  },
  SET_STATUS: (state, value) => {
    state.status = value
  },
  SET_IS_ERROR: (state, value) => {
    state.isError = value
  },
  SHIFT_CHUNK: state => {
    state.chunks.shift()
  },
  ADD_RETRY_COUNT: state => {
    state.retryCount++
  },
  SET_RETRY: (state, value) => {
    state.retry = value
  },
  TURN_OFF_NOTIFICATION: state => {
    state.showNotification = false
  },
  TURN_ON_NOTIFICATION: state => {
    state.showNotification = true
  }
}

const actions = {
  select({ state, commit }, event) {
    if (event.target.files.length === 0) {
      commit('CLEAN_VARIABLES')
      return
    }

    const file = event.target.files.item(0)
    commit('SET_FILE', file)
    commit('CREATE_CHUNKS')

    commit('SET_UPLOADED', 0)
    commit('SET_RETRY_COUNT', 0)
    commit('TURN_OFF_NOTIFICATION')

    SHOW_LOG && console.log(MESSAGE_FILE_SIZE(state.file?.size))
  },
  async upload({ commit, dispatch }) {
    commit('SET_UPLOADING', true)
    commit('TURN_ON_NOTIFICATION')

    const config = await dispatch('config')

    try {
      const response = await axios(config)

      if (response.data?.uploaded) {
        commit('SET_STATUS', { type: 'info', message: STATUS_IN_PROGRESS })
        commit('SET_IS_ERROR', false)
        commit('SET_RETRY_COUNT', 0)
        commit('SHIFT_CHUNK')
        dispatch('doUpload')
      }
    } catch (error) {
      SHOW_LOG && console.log(error)
      if (error) {
        SHOW_LOG && console.log(error.response?.data?.message)

        if (error.response?.data?.errors?.file[0] === MESSAGE_ILLIGAL_FILE_TYPE) {
          dispatch('cancelUpload')
          commit('SET_STATUS', { type: 'warning', message: MESSAGE_ILLIGAL_FILE_TYPE })
        } else {
          const errorMessage = error.response.data?.message ? error.response.data?.message : error
          commit('SET_STATUS', { type: 'warning', message: errorMessage })
          commit('SET_IS_ERROR', true)
          dispatch('doUpload')
        }
      }
    }
  },
  doUpload({ state, commit, dispatch }) {
    if (!state.isError) {
      if (state.chunks.length > 0) {
        SHOW_LOG && console.log(MESSAGE_UPLOADING_CHUNK(state.totalChunks, state.chunks.length))
        dispatch('upload')
      }
    } else {
      if (state.retry) {
        commit('ADD_RETRY_COUNT')
        SHOW_LOG && console.log(MESSAGE_RETRYING(state.retryCount, state.maxRetry))
        if (state.retryCount >= state.maxRetry) {
          commit('SET_RETRY', false)
        }
        state.currentTimer = setTimeout(() => dispatch('upload'), TIME_TO_WAIT_BEFORE_RETRY)
      } else {
        SHOW_LOG && console.log(MESSAGE_MAX_ATTEMPS_REACHED)
      }
    }
  },
  cancelUpload({ state, commit }) {
    commit('CLEAN_VARIABLES')
    clearTimeout(state.currentTimer)
    state.cancel(MESSAGE_UPLOAD_CANCELED)
    SHOW_LOG && console.log(MESSAGE_UPLOAD_CANCELED)
    commit('SET_STATUS', { type: 'danger', message: MESSAGE_UPLOAD_CANCELED })
  },

  async formData({ state }) {
    var formData = new FormData()

    formData.set('is_last', state.chunks.length === 1)
    formData.set('file', state.chunks[0], `${state.file?.name}.part`)

    return formData
  },
  async config({ commit, dispatch }) {
    const CancelToken = axios.CancelToken
    const formData = await dispatch('formData')
    const cancelToken = new CancelToken(c => {
      commit('SET_CANCEL', c)
    })

    return {
      method: 'POST',
      url: API_URL_UPLOAD,
      data: formData,
      headers: {
        'Content-Type': 'application/octet-stream'
      },
      onUploadProgress: event => commit('ADD_UPLOADED', event.loaded),
      cancelToken
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
