<template>
  <div id="app">
    <Uploader />
  </div>
</template>

<script>
import Uploader from './components/Uploader'

export default {
  name: 'App',
  components: {
    Uploader
  }
}
</script>
