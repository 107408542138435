<template>
  <div>
    <div class="flex items-center justify-center min-h-screen px-5 py-5 bg-gray-900 min-w-screen">
      <div class="w-full px-5 py-5 text-gray-500 bg-gray-800 rounded shadow-xl sm:w-2/3 md:w-1/2 lg:w-1/3">
        <div class="flex w-full">
          <h3 class="flex-1 text-lg font-semibold leading-tight select-none">File Uploader</h3>
        </div>
        <div class="relative p-2 mt-2 overflow-hidden border-t-2 border-indigo-200 border-solid transition-all duration-500">
          <div>
            <div class="flex flex-col items-center justify-center">
              <div class="flex flex-col justify-center w-full">
                <h4 v-show="filename" class="font-extrabold"><span class="font-normal select-none">{{ filename }}</span></h4>

                <notification :status="status" />

              </div>

              <div class="flex justify-center w-full">
                <div v-show="uploading" class="flex-none w-full p-4 mt-5 rounded-md">
                  <div class="mt-2 bg-gray-600 rounded-full">
                    <div :style="`width: ${(progress < 3) ? 3 : progress}%;`" class="py-0 mt-2 text-center bg-indigo-400 rounded-full ">
                      <div class="inline-block px-2 text-sm text-gray-200 bg-indigo-700 rounded-full ">
                        <span class="select-none">{{ progress }}%</span>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

              <div class="flex flex-col items-center content-center justify-center mt-5">
                <label v-show="!uploading" class="flex flex-col items-center w-64 px-4 py-6 tracking-wide text-indigo-900 uppercase bg-indigo-400 border border-indigo-500 rounded-lg shadow-lg cursor-pointer hover:bg-indigo-700 hover:text-indigo-300">
                  <svg
                    class="w-8 h-8"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path fill-rule="evenodd" d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z" />
                  </svg>
                  <span class="mt-2 text-base leading-normal select-none">Select a file</span>
                  <input
                    v-show="!uploading"
                    ref="fileInput"
                    type="file"
                    class="hidden"
                    @click="clearInput"
                    @change="select"
                  >
                </label>

                <button
                  v-show="!uploading"
                  :disabled="!fileLoaded"
                  :class="fileLoaded ? 'bg-teal-400 bg-opacity-100 hover:bg-teal-700 text-teal-900 hover:text-teal-300' : 'bg-gray-300 text-gray-900 cursor-not-allowed bg-opacity-50'"
                  class="inline-flex items-center justify-center w-40 h-8 px-4 py-2 mt-5 rounded outline-none cursor-pointer focus:outline-none"
                  @click="upload"
                >
                  <svg
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    class="w-4 h-4 mr-2 fill-current"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path fill-rule="evenodd" d="M8 0a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 4.095 0 5.555 0 7.318 0 9.366 1.708 11 3.781 11H7.5V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11h4.188C14.502 11 16 9.57 16 7.773c0-1.636-1.242-2.969-2.834-3.194C12.923 1.999 10.69 0 8 0zm-.5 14.5V11h1v3.5a.5.5 0 0 1-1 0z" />
                  </svg>
                  <span class="select-none">Upload</span>
                </button>

                <button
                  v-show="uploading"
                  class="inline-flex items-center justify-center w-40 h-8 px-4 py-2 mt-5 text-pink-900 bg-pink-400 rounded outline-none cursor-pointer hover:bg-pink-700 hover:text-pink-300 focus:outline-none"
                  @click="cancelUpload"
                >
                  <svg
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    class="w-4 h-4 mr-2 fill-current"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path fill-rule="evenodd" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                  </svg>
                  <span class="select-none">Cancel</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { SHOW_LOG, MESSAGE_UPLOAD_SUCCESS } from '../config.js'
import Notification from './Notification'

export default {
  components: {
    Notification
  },
  data: () => ({ }),
  computed: {
    ...mapGetters([
      'chunks',
      'fileLoaded',
      'currentChunk',
      'filename',
      'progress',
      'status',
      'retryCount',
      'maxRetry',
      'uploading'
    ])
  },
  watch: {
    chunks(n, o) {
      if (n.length === 0 && this.uploading) {
        SHOW_LOG && console.log(MESSAGE_UPLOAD_SUCCESS)
        this.SET_STATUS({ type: 'success', message: MESSAGE_UPLOAD_SUCCESS })
        this.CLEAN_VARIABLES(this.$refs?.fileInput)
      }
    }
  },
  methods: {
    ...mapMutations('upload', [
      'SET_STATUS',
      'CLEAN_VARIABLES',
      'TURN_OFF_NOTIFICATION'
    ]),
    ...mapActions('upload', [
      'cleanVariables',
      'select',
      'upload',
      'cancelUpload'
    ]),
    clearInput(e) {
      e.target.value = ''
    }
  }
}
</script>
