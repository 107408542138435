<template>
  <div
    v-show="showNotification"
    class="flex items-center justify-center px-2 py-1 m-1 mt-3 font-medium bg-white border rounded-md"
    :class="notificationStyle"
  >

    <div
      v-show="status.type === 'success'"
      class="flex items-center justify-center flex-shrink-0 w-5 h-5 bg-green-100 border-2 border-green-500 rounded-full alert-icon"
    >
      <span class="text-green-500">
        <svg
          fill="currentColor"
          viewBox="0 0 20 20"
          class="w-4 h-4"
        >
          <path
            fill-rule="evenodd"
            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
            clip-rule="evenodd"
          />
        </svg>
      </span>
    </div>

    <div
      v-show="status.type === 'info'"
      class="flex items-center justify-center flex-shrink-0 w-5 h-5 bg-blue-100 border-2 border-blue-500 rounded-full alert-icon"
    >
      <span class="text-blue-500">
        <svg
          fill="currentColor"
          viewBox="0 0 20 20"
          class="w-4 h-4"
        >
          <path
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
            clip-rule="evenodd"
          />
        </svg>
      </span>
    </div>

    <div

      v-show="status.type === 'warning'"
      class="alert-icon flex items-center bg-yellow-100 border-2 border-yellow-500 justify-center h-5 w-5 flex-shrink-0 rounded-full"
    >
      <span class="text-yellow-500">
        <svg
          fill="currentColor"
          viewBox="0 0 20 20"
          class="w-4 h-4"
        >
          <path
            fill-rule="evenodd"
            d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
            clip-rule="evenodd"
          />
        </svg>
      </span>
    </div>

    <div
      v-show="status.type === 'danger'"
      class="flex items-center justify-center flex-shrink-0 w-5 h-5 bg-red-100 border-2 border-red-500 rounded-full alert-icon"
    >
      <span class="text-red-500">
        <svg
          fill="currentColor"
          viewBox="0 0 20 20"
          class="w-4 h-4"
        >
          <path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          />
        </svg>
      </span>
    </div>

    <div class="flex-initial max-w-full ml-2 font-normal select-none text-md">
      {{ status.message }}
    </div>
    <div class="flex flex-row-reverse flex-auto">
      <div
        @click="closeNotification"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="w-5 h-5 ml-2 rounded-full cursor-pointer feather feather-x hover:text-red-400"
        >
          <line
            x1="18"
            y1="6"
            x2="6"
            y2="18"
          />
          <line
            x1="6"
            y1="6"
            x2="18"
            y2="18"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  props: {
    status: {
      type: Object,
      required: true,
      default: () => ({
        type: 'info',
        message: 'Stand by'
      })
    }
  },
  computed: {
    ...mapGetters([
      'showNotification'
    ]),
    notificationStyle() {
      switch (this.status.type) {
        case 'success':
          return 'text-green-700 bg-green-100 border-green-300'
        case 'info':
          return 'text-blue-700 bg-blue-100 border-blue-300'
        case 'warning':
          return 'text-yellow-700 bg-yellow-100 border-yellow-300'
        case 'danger':
          return 'text-red-700 bg-red-100 border-red-300'
        default:
          return 'text-blue-700 bg-blue-100 border-blue-300'
      }
    }
  },
  methods: {
    ...mapMutations('upload', [
      'TURN_OFF_NOTIFICATION'
    ]),
    closeNotification() {
      console.log('close notification')
      this.TURN_OFF_NOTIFICATION()
    }
  }
}
</script>

<style>

</style>
