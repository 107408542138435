const getters = {
  chunks: state => state.upload.chunks,
  fileLoaded: state => state.upload.file,
  currentChunk: state => state.upload.totalChunks - state.upload.chunks.length,
  filename: state => state.upload.file?.name ? state.upload.file?.name : '',
  progress: (state, getters) => {
    if (!state.upload.file?.size) return 0

    return Math.floor((getters.currentChunk * 100) / state.upload.totalChunks)
  },
  status: state => state.upload.status,
  retryCount: state => state.upload.retryCount,
  maxRetry: state => state.upload.maxRetry,
  uploading: state => state.upload.uploading,
  fileSize: state => state.upload.file?.size,
  showNotification: state => state.upload.showNotification
}

export default getters
