export const SHOW_LOG = true

export const TIME_TO_WAIT_BEFORE_RETRY = 6000
export const API_URL_UPLOAD = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
  ? process.env.VUE_APP_API_URL_DEVELOPMENT
  : process.env.VUE_APP_API_URL_PRODUCTION

export const STATUS_IN_PROGRESS = 'In progress...'
export const CHUNK_SIZE = 1 * 1048576 // 1048576 = 1MB
export const MESSAGE_UPLOAD_CANCELED = 'Operation canceled by the user'
export const MESSAGE_MAX_ATTEMPS_REACHED = 'Maximum number of attempts reached'
export const MESSAGE_UPLOAD_SUCCESS = 'File uploaded successfully'
export const MESSAGE_UPLOADING_CHUNK = (total, chunksLenght) => (`Uploading chunk: ${total - chunksLenght}`)
export const MESSAGE_RETRYING = (retryCount, maxRetry) => (`Retrying ${retryCount} / ${maxRetry}`)
export const MESSAGE_FILE_SIZE = size => (`File size: ${size}`)
export const MESSAGE_ILLIGAL_FILE_TYPE = 'Illegal file type'

export default {
  SHOW_LOG,
  TIME_TO_WAIT_BEFORE_RETRY,
  API_URL_UPLOAD,
  STATUS_IN_PROGRESS,
  CHUNK_SIZE,
  MESSAGE_UPLOAD_CANCELED,
  MESSAGE_MAX_ATTEMPS_REACHED,
  MESSAGE_UPLOADING_CHUNK,
  MESSAGE_RETRYING,
  MESSAGE_FILE_SIZE,
  MESSAGE_ILLIGAL_FILE_TYPE
}
